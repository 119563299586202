import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../components/Layout';
import { StaticImage } from "gatsby-plugin-image";
import WanderLost from "../images/404.jpg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout pageTitle="404" mdxType="Layout">
      <h1>{`404: You have found the middle of nowhere.`}</h1>
      <img src={WanderLost} alt="Image of Kelsey wandering the mountains" />
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      